@import "styles/_mixins.scss";
.floatingBar{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  width: 100vw;

  .background{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    background-color: var(--yellowDarker1);
    border-width: 1px 0 0 0;
    @include gold-border;
  }

  .inner{
    $collapse1: 900px;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--sectionInnerMaxWidth);
    padding-top: var(--spacing4);
    padding-bottom: var(--spacing4);
    @media (max-width: $collapse1){
      flex-direction: column;
      align-items: flex-start;
    }

    .headline{
      position: relative;

      margin-bottom: var(--spacing2);

      font-weight: 700;
      font-size: var(--fontSize3);
      font-family: var(--secondaryFont);
      @media (max-width: $collapse1){
        font-size: var(--fontSize2);
      }

      > div:not(:first-child){
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .subheadline{
      position: relative;

      font-style: italic;
      @media (max-width: $collapse1){
        margin-right: 2rem;

        font-size: var(--fontSize1);
      }
      > div:not(:first-child){
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .controls{
      display: flex;

      .cta{
        position: relative;
        .ctaButton{
          margin: 0 var(--spacing7);
          @media (max-width: $collapse1){
            margin: var(--spacing5) 0 0 0;
          }
          &:not(:first-child){
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .cancel{
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        background-color: transparent;
        border: none;
        @media (max-width: $collapse1){
          position: absolute;
          top: var(--spacing4);
          right: var(--spacing4);
        }

        svg{
          width: 2rem;
          height: 2rem;

          filter: brightness(75%);

          transition: filter 0.1s ease-in-out;
          path{
            transition: fill 0.1s ease-in-out;
          }
        }

        &:hover{
          svg{
            filter: brightness(100%);
            path{
              fill: var(--yellow);
            }
          }
        }
      }
    }
  }


  .countdown{
    margin: 0 var(--spacing7) 0 0;
    @media (max-width: 900px){
      margin: var(--spacing5) var(--spacing7) 0 0;
    }

    .counter{
      display: grid;
      grid-template-columns: 3ch 3ch 3ch min-content 3ch 3ch min-content 3ch 3ch;
      gap: var(--spacing3);

      font-size: var(--fontSize3);

      .digit{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--spacing5) var(--spacing3);

        color: var(--white);
        font-weight: 400;
        font-family: var(--secondaryFont);
        line-height: 1em;

        background-color: var(--black);

        &.marker{
          background-color: transparent;
        }
      }

      .label{
        font-weight: 400;
        font-size: var(--fontSize0);
        font-family: var(--secondaryFont);
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
      }

      .hLabel{
        grid-column: span 3;
      }

      .mLabel{
        grid-column: 5/7;
      }
      .sLabel{
        grid-column: 8/10;
      }
    }
  }
}
