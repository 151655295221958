@import "styles/_mixins.scss";
.modalWrapper{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  > svg{
    z-index: 1;

    width: auto;

    min-width: calc(max(100vw, 100vh));
    height: auto;
    min-height: calc(min(100vw, 100vh));
  }

  .modal{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    .modalInner{
      position: relative;

      max-width: 500px;
      padding: var(--spacing6) var(--spacing8);

      background: hsla(var(--brownHsl), 0.1);

      border-width: 1px;
      @include gold-border;
      @media (max-width: 500px){
        position: static;

        padding: var(--spacing6) var(--spacing6);

        background-color: var(--black);
        border-width: 0;
      }

      &.fullscreen{
        position: static;

        background-color: transparent;
        border-width: 0;
      }

      .button{
        width: 100%;
      }

      .cancel{
        position: absolute;
        top: var(--spacing5);
        right: var(--spacing5);
        z-index: 100;

        width: 1.5rem;
        height: 1.5rem;
        padding: 0;

        background-color: transparent;
        border: none;
        svg{
          width: 100%;
          height: 100%;

          transition: transform 0.1s ease-in-out;
          path{
            transition: fill 0.1s ease-in-out;
          }
        }


        &:hover,
        &:active,
        &:focus{
          svg{
            transform: scale(1.1, 1.1);
            path{
              fill: var(--yellow);
            }
          }
        }
      }
    }
  }
}

