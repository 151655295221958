@import "styles/_mixins.scss";
.modalWrapper{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  width: 100vw;
  height: 100vh;

  pointer-events: none;

  > svg{
    z-index: 1;

    width: auto;

    min-width: calc(max(100vw, 100vh));
    height: auto;
    min-height: calc(min(100vw, 100vh));
  }

  .modal{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    .modalInner{
      position: relative;

      max-width: 500px;
      padding: var(--spacing6);

      text-align: center;

      border-width: 1px;
      @media (max-width: 500px){
        border-width: 0;
      }

      .headline{
        margin-bottom: var(--spacing4);

        color: var(--yellow);
        font-size: var(--fontSize3);
        font-family: var(--secondaryFont);
        text-align: center;
      }

      .loadingBar{
        width: 300px;
        height: 0.75rem;

        border: 1px solid var(--yellow);

        .coloredBg{
          width: 100%;
          height: 100%;

          background-color: var(--yellow);
          transform: scale(0, 1);
          transform-origin: top left;
        }
      }

      .loading{
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p{
        margin-top: var(--spacing4);

        font-size: var(--fontSize2);
        letter-spacing: 1px;
      }

      .errorMessage{
        margin-top: var(--spacing4);

        font-size: var(--fontSize2);
        letter-spacing: 1px;
        color: #ff0000;
        font-weight: 700;
      }
    }
  }
}


.challengeIcons{
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  justify-content: center;
  width: 100%;
  height: 100%;

  opacity: 0.05;
  filter: grayscale(100%) brightness(0) invert(100%);

  svg{
    width: min(80vw, 80vh);
    height: min(80vw, 80vh);
  }
}
