.button{
  --foreground: var(--black);
  --foregroundHover: var(--black);
  --background: var(--yellow);
  --backgroundHover: var(--brownLighter1);


  &.ghost{
    --foreground: var(--yellow);
    --foregroundHover: var(--black);
    --background: transparent;
    --backgroundHover: var(--yellow);
  }


  &.grayscale{
    &.ghost{
      --foreground: var(--white);
      --foregroundHover: var(--black);
      --background: transparent;
      --backgroundHover: var(--white);
    }
  }


  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: min-content;
  height: 3.25rem;
  padding: var(--spacing4) var(--spacing6);

  color: var(--foreground);
  font-weight: 700;
  font-size: var(--fontSize2);
  font-family: var(--primaryFont);
  line-height: 0;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;

  background-color: var(--background);
  border: 0 solid transparent;
  border-radius: var(--curve);
  box-shadow: var(--elevation2);
  cursor: pointer;

  transition: all 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  user-select: none;

  svg{
    width: auto;
    height: 1.5rem;
    margin-right: var(--spacing3);
    path{
      transition: fill 0.1s ease-in-out;

      fill: var(--foreground);
    }
  }


  /*stylelint-disable-next-line no-duplicate-selectors*/
  &.ghost{
    border: 1px solid var(--foreground);
    box-shadow: var(--elevation0);
  }

  &:hover{
    color: var(--foregroundHover);

    background-color: var(--backgroundHover);
    box-shadow: var(--elevation4);
    svg path{
      fill: var(--foregroundHover);
    }
  }

  &:focus,
  &:active{
    color: var(--foregroundHover);

    background-color: var(--backgroundHover);
    box-shadow: var(--elevation0);
    filter: brightness(90%);
    svg path{
      fill: var(--foregroundHover);
    }
  }
}

