@import "styles/_mixins.scss";

.registerWrapper{
  $fullscreenWidth: 450px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  padding: var(--spacing6) var(--spacing6);
  overflow: hidden;
  box-sizing: border-box;

  background-color: var(--black);
  border-width: 1px;
  @include gold-border;
  @media (max-width: $fullscreenWidth){
    box-sizing: border-box;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;

    border-width: 0;
  }

  .background{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    filter: sepia(100%) brightness(5%);

    img{
      width: 100%;
      height: 100%;
    }
  }

  .logo{
    position: relative;
    z-index: 1;

    width: 100%;
    svg{
      width: 100%;
      height: auto;
      margin-bottom: var(--spacing6);
    }
  }

  .header{
    position: relative;
    z-index: 1;

    align-self: flex-start;
    margin-bottom: var(--spacing6);

    color: var(--yellow);

    font-weight: 700;
    font-size: var(--fontSize4);
    font-family: var(--secondaryFont);
    text-align: left;
  }


  p{
    position: relative;
    z-index: 1;

    margin: 0 0 var(--spacing6) 0;
  }

  .providers{
    position: relative;
    z-index: 1;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--spacing5);
    width: 100%;
  }

  .providerButton{
    justify-content: flex-start;
    width: 100%;
  }
  .message{
    position: relative;
    z-index: 1;

    align-self: flex-start;
    margin-top: var(--spacing3);

    color: var(--invalid);

    font-weight: 700;
  }

  .separator{
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    width: 100%;
    margin: var(--spacing6) 0;


    &::before,
    &::after{
      display: block;
      width: 100%;

      border-top: 1px solid var(--yellow3);

      content: "";
    }

    >*{
      padding: 0 var(--spacing4);

      color: var(--yellow3);

      font-weight: 700;
      font-size: var(--fontSize3);
      font-family: var(--secondaryFont);
      text-transform: uppercase;
    }
  }

  .row{
    display: flex;
    margin-bottom: var(--spacing5);
    >*:not(:last-child){
      margin-right: var(--spacing5);
    }
  }

  .input{
    width: 100%;
    &:not(:last-child){
      margin-bottom: var(--spacing5);
    }
  }

  .button{
    position: relative;
    z-index: 1;

    width: 100%;
  }

  .links{
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: var(--spacing6);
    a{
      align-self: flex-start;

      &:not(:last-child){
        margin-bottom: var(--spacing4);
      }
    }
  }
}
