.main{
  min-height: max(100vw, 100vh);
}


.infiniteArt{
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: max(100vw, 100vh);
  height: max(100vw, 100vh);
  overflow: hidden;

  .pixiView{
    position: relative;
    z-index: 0;

    width: max(100vw, 100vh);
    height: max(100vw, 100vh);
    overflow: hidden;

    transform: translate(calc(50vw - 50%), 0);

    touch-action: auto !important;
  }


  .infoTab{
    position: absolute;
    top: 50%;
    left: 0;

    width: 2rem;
    height: 2rem;

    padding: var(--spacing3);

    background-color: #00000088;

    border: 1px solid var(--yellow);
    svg{
      width: 100%;
      height: 100%;
    }
  }
}


.signinWall{
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  width: 100%;
  height: 100%;
  min-height: max(100vh, 100vw);

  backdrop-filter: sepia(100%) brightness(40%);

  &.hidden{
    opacity: 0;

    pointer-events: none;
  }

  >div{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    padding: var(--spacing5);
    @media (max-width: 450px){
      padding: 0;
    }

    > *{
      background-color: #000000bb;
    }
  }
}

.modalInner{
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  user-select: none;

  .modalHeader{
    margin-bottom: var(--spacing3);

    color: var(--yellow);
    font-weight: 700;
    font-size: var(--fontSize4);
    font-family: var(--secondaryFont);
  }

  .modalSubheader{
    font-weight: 700;
    font-size: var(--fontSize3);
    font-style: italic;
    @media (max-width: 400px){
      font-size: var(--fontSize2);
    }
  }

  .text{
    max-width: 350px;
    margin-bottom: var(--spacing6);

    text-align: left;
    p:last-child{
      margin-bottom: 0;
    }
  }
  .controls{
    display: flex;
    gap: var(--spacing5);
    @media (max-width: 400px){
      flex-direction: column;
      align-items: center;
    }
  }

  .challengeName{
    text-transform: capitalize;
  }

  .modalIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing4);
  }
}

.adminPanel{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;


  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: var(--spacing5);

  background-color: #00000088;

  >*{
    width: 100% !important;
    &:not(:last-child){
      margin-bottom: var(--spacing5);
    }
  }
}

