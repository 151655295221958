.floatingTextInput{
  --color: var(--white);
  --accent: var(--yellow);
  position: relative;

  .inputHolder{
    position: relative;

    height: 3.2rem;
  }

  &.multiline{
    .inputHolder{
      height: min-content;
    }
  }

  .message{
    color: #888888;
    font-weight: 700;
    font-size: var(--fontSize1);

    &:not(:empty){
      height: 1.3em;
    }
  }


  .suggestions{
    position: absolute;
    z-index: 100;

    display: none;

    box-sizing: border-box;

    width: 100%;

    max-height: 300px;
    overflow: hidden;

    background: var(--iaaBlack);
    border: 0 solid transparent;
    border-radius: var(--curve);
    box-shadow: var(--elevation4);


    .suggestionsInner{
      box-sizing: border-box;
      max-height: 300px;
      overflow: auto;

      border: 1px solid var(--iaaColor4);
      border-radius: var(--curve);

      &::-webkit-scrollbar-track{
        background: transparent;
      }
      .suggestion{
        padding: var(--spacing5);

        color: var(--iaaColor4);

        cursor: default;

        user-select: none;

        &:hover{
          color: var(--iaaBlack);

          background: var(--iaaColor4);
        }

        &.hidden{
          display: none;
        }
      }


      &:empty,
      &.hidden{
        display: none;
      }

      &:not(:hover){
        >:first-child{
          color: var(--iaaBlack);

          background: var(--iaaColor4);
        }
      }
    }
  }

  input.input,
  textarea{
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 0 0 0;
    padding: var(--spacing6) var(--spacing4) var(--spacing3) var(--spacing4);

    color: var(--color);

    font-size: var(--fontSize2);
    font-family: inherit;

    background-color: transparent;

    /*stylelint-disable-next-line declaration-no-important*/
    border: 0 solid #ff0000!important;

    &::placeholder{
      /*stylelint-disable-next-line declaration-no-important*/
      opacity: 0 !important;
    }

    &:focus{
      outline: none;
    }
  }

  textarea{
    min-height: 5rem;

    resize: none;
  }

  label{
    position: absolute;
    top: 0;

    box-sizing: border-box;

    width: 100%;
    height: 100%;

    border: 1px solid #888888;
    border-radius: var(--curve);

    transition: border-color 0.1s ease-in-out;

    user-select: none;

    pointer-events: none;


    span{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      display: flex;
      align-items: center;
      height: 100%;
      padding-left: var(--spacing4);

      color: var(--color);


      white-space: nowrap;

      transition: height 0.25s ease-in-out, font-size 0.25s ease-in-out, font-weight 0.25s ease-in-out, color 0.1s ease-in-out;
      transition-property: height, font-size, font-weight, color;
    }
  }

  textarea ~ label{
    height: 100%;
  }


  input:not(:placeholder-shown) ~ label span,
  input:focus ~ label span,
  textarea:not(:placeholder-shown) ~ label span,
  textarea:focus ~ label span{
    height: 2em;

    font-weight: 700;

    font-size: var(--fontSize0);
  }

  input:hover ~ label,
  input:focus ~ label,
  textarea:hover ~ label,
  textarea:focus ~ label{
    border-color: var(--accent);
    span{
      color: var(--accent);
    }
  }

  input:focus ~ .suggestions:not(.hidden):not(:empty),
  textarea:focus ~ .suggestions:not(.hidden):not(:empty){
    display: block;
  }


  &.valid{
    label{
      border-color: var(--valid);
      span{
        color: var(--valid);
      }
    }
    .message{
      color: var(--valid);
    }
  }
  &.invalid{
    label{
      border-color: var(--invalid);
      span{
        color: var(--invalid);
      }
    }
    .message{
      color: var(--invalid);
    }
  }
}

