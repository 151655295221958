.footer{
  $collapse1: 550px;
  display: flex;
  justify-content: center;
  .top{
    display: flex;
    justify-content: space-between;
    @media (max-width: $collapse1){
      flex-direction: column;
      align-items: center;
    }

    .label{
      margin: 0 0 var(--spacing4) 0;

      color: var(--yellow);

      font-weight: bold;
      font-size: var(--fontSize3);
      font-family: var(--secondaryFont);
    }
    .links{
      display: flex;
      flex-direction: column;

      @media (max-width: $collapse1){
        text-align: center;
        align-items: center;
      }

      a{
        width: min-content;
        margin: 0 0 var(--spacing4) 0;

        font-weight: 400;
        white-space: nowrap;
      }
    }

    .social{
      display: flex;
      flex-direction: column;
      @media (max-width: $collapse1){
        margin: var(--spacing7) 0;
        text-align: center;
        align-items: center;
      }


      .label{
        text-align: center;
        @media (max-width: $collapse1){
          text-align: left;
        }
      }
    }

    .socialLinks{
      display: flex;

      .socialLink{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;

        background-color: var(--yellow);
        border-radius: 50%;

        transition: all 0.1s ease-in-out;

        &:not(:last-child){
          margin: 0 var(--spacing5) 0 0;
        }

        svg{
          width: 1.5rem;
          height: 1.5rem;
          path{
            fill: var(--black);
          }
        }

        &:hover{
          background-color: var(--yellowLighter1);
          transform: scale(1.1, 1.1);
        }
      }
    }
  }

  .bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing5);
    border-top: 1px solid var(--gray3);
    padding: var(--spacing5) var(--spacing5);

    .copyright{
      width: 100%;

      color: var(--gray3);
      text-align: center;
      font-size: var(--fontSize1);

      &:not(:last-child){
        margin-bottom: var(--spacing3);
      }

    }
    a{
      color: var(--gray3);
    }
  }



  &.minimal{
    .top{
      justify-content: center;
      align-items: center;
    }
  }
}
